import {
  Checkbox,
  ComboBox,
  IComboBoxProps,
  IDatePickerStrings,
  TextField,
  defaultDatePickerStrings
} from "@fluentui/react";
import { Field, useFormikContext } from "formik";
import React, { useEffect } from "react";
import { AsyncSubject } from "rxjs";
import { JemConfiguration } from "../../../../JemConfiguration";
import { convertToRecurringScheduleFrequencyOptions, LoadingStatus } from "../../../../Shared";
import { DatePickerField } from "../../../../Shared/components/DatePickerField/DatePickerField";
import { SimpleShimmerForForms } from "../../../../Shared/components/SimpleShimmerForForms/SimpleShimmerForForms";
import { DomainDataEnum, DomainDataObjects } from "../../../../Shared/contexts/JEMContext/JEMContext.domainData.types";
import { ForceMaxWidthAndHeightOnDropdownStyle } from "../../../../Shared/utilities/FluentUIHacks";
import { FCWChecklistModel } from "../../../utilities";
import { GLPageState } from "../GLCreate.State";
import { useConst } from "@fluentui/react-hooks";
import { GLCreateFormikState } from "../GLCreateForm.types";

export interface GLCreateRecurringJeDetailsProps {
  configuration: JemConfiguration["GeneralLedgerApi"];
  pageState: GLPageState;
  disabled: boolean;
  loadingStatus: LoadingStatus;
  domainData: Pick<DomainDataObjects, DomainDataEnum.RecurringScheduleFrequency>;
  onFiscalPeriodChange: (confirmation: AsyncSubject<boolean>, fiscalPeriod: string) => void;
  onChecklistChange: (confirmation: AsyncSubject<boolean>, checklist: FCWChecklistModel) => void;
  onPostingDateChange: (confirmation: AsyncSubject<boolean>, postingDate: Date) => void;
}

const GLCreateRecurringJeDetails: React.FC<GLCreateRecurringJeDetailsProps> = (props) => {
  const formik = useFormikContext<GLCreateFormikState>();
  const createState = props.pageState.createState;
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate());

  const frequencyDropdownOptions = convertToRecurringScheduleFrequencyOptions(
    props.domainData.RecurringScheduleFrequency || []
  );

  useEffect(() => {
    if (!formik.values.recurringDetailsTabNextPostingDate) {
      formik.setFieldValue("recurringDetailsTabNextPostingDate", formik.values.detailsTabPostingDate);
    }
  }, [formik.values.detailsTabPostingDate]);

  useEffect(() => {
    if (formik.values.recurringDetailsTabEndDate) {
      const endDate = new Date(formik.values.recurringDetailsTabEndDate).getTime();
      const today = new Date().getTime();
      formik.setFieldValue("recurringDetailsTabIsLongTerm", Math.abs(endDate - today) / (1000 * 60 * 60 * 24) > 365);
    }
  }, [formik.values.recurringDetailsTabEndDate]);

  // useEffect(() => {
  //   if (
  //     formik.values.recurringDetailsTabEndDate &&
  //     formik.values.recurringDetailsTabStartDate &&
  //     formik.values.recurringDetailsTabEndDate < formik.values.recurringDetailsTabStartDate
  //   ) {
  //     formik.setFieldError("recurringDetailsTabEndDate", "Invalid End Date. Start date should be before the end date.");
  //   }
  //   if (formik.values.recurringDetailsTabFrequency && formik.values.recurringDetailsTabStartDate) {
  //     // set posting date
  //     formik.setFieldValue("recurringDetailsTabNextPostingDate", formik.values.recurringDetailsTabStartDate);
  //   }
  // }, [
  //   formik.values.recurringDetailsTabFrequency,
  //   formik.values.recurringDetailsTabStartDate,
  //   formik.values.recurringDetailsTabEndDate
  // ]);

  const outOfBoundsErrorMessage: IDatePickerStrings = useConst(() => ({
    ...defaultDatePickerStrings,
    isOutOfBoundsErrorMessage: "Posting Date should be in the current Posting Period."
  }));

  const onFrequencyChange =
    (fieldName: string): IComboBoxProps["onChange"] =>
    (_event, item, _index, _value) => {
      if (!item) return;
      formik.setFieldValue(fieldName, item.key);
    };

  return (
    <>
      <div className="ms-Grid" dir="ltr">
        <b>Populate the fields on this page ONLY if you want this JE to be part of a recurring JE schedule</b>
        <div className="ms-Grid-row">
          <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6 ms-xl4">
            <div className="ms-Grid" dir="ltr">
              <div className="ms-Grid-row">
                <SimpleShimmerForForms loadingStatus={props.loadingStatus} height={32} label="Frequency">
                  <Field
                    label="Frequency"
                    name={"recurringDetailsTabFrequency"}
                    onChange={onFrequencyChange("recurringDetailsTabFrequency")}
                    as={ComboBox}
                    options={frequencyDropdownOptions}
                    styles={ForceMaxWidthAndHeightOnDropdownStyle({ width: "100%", height: "auto" })}
                    allowFreeform={true}
                    autoComplete="on"
                    selectedKey={
                      formik.values.recurringDetailsTabFrequency ? formik.values.recurringDetailsTabFrequency : ""
                    }
                    disabled={props.disabled || createState.isExternalDraft}
                    errorMessage={props.disabled ? "" : formik.errors.recurringDetailsTabFrequency}
                  />
                </SimpleShimmerForForms>
              </div>
              <div className="ms-Grid-row">
                <SimpleShimmerForForms loadingStatus={props.loadingStatus} height={32} label="Start Date">
                  <Field
                    name="recurringDetailsTabStartDate"
                    label="Start Date"
                    component={DatePickerField}
                    minDate={tomorrow}
                    // maxDate={new Date(formik.values.detailsTabPostingDate)}
                    strings={outOfBoundsErrorMessage}
                    disabled={props.disabled}
                    errorMessage={
                      formik.errors.recurringDetailsTabStartDate ? formik.errors.recurringDetailsTabStartDate : ""
                    }
                  />
                </SimpleShimmerForForms>
              </div>
              <div className="ms-Grid-row">
                <SimpleShimmerForForms loadingStatus={props.loadingStatus} height={32} label="Next Posting Date">
                  <Field
                    name="recurringDetailsTabNextPostingDate"
                    label="Next Posting Date"
                    component={DatePickerField}
                    strings={outOfBoundsErrorMessage}
                    disabled={true}
                  />
                </SimpleShimmerForForms>
              </div>
              <div className="ms-Grid-row">
                <SimpleShimmerForForms loadingStatus={props.loadingStatus} height={32} label="End Date">
                  <Field
                    name="recurringDetailsTabEndDate"
                    label="End Date"
                    component={DatePickerField}
                    minDate={formik.values.recurringDetailsTabStartDate}
                    strings={outOfBoundsErrorMessage}
                    disabled={props.disabled}
                    errorMessage={
                      formik.errors.recurringDetailsTabEndDate ? formik.errors.recurringDetailsTabEndDate : ""
                    }
                    required
                  />
                </SimpleShimmerForForms>
              </div>
              <div className="ms-Grid-row">
                <SimpleShimmerForForms
                  loadingStatus={props.loadingStatus}
                  height={32}
                  label="Recurring JE Schedule Name"
                >
                  <Field
                    as={TextField}
                    label="Recurring JE Schedule Name"
                    name="recurringDetailsTabScheduleName"
                    type="input"
                    disabled={props.disabled || formik.values.recurringDetailsTabRJEScheduleId}
                  />
                </SimpleShimmerForForms>
              </div>
              <div className="ms-Grid-row">
                <SimpleShimmerForForms loadingStatus={props.loadingStatus} height={32}>
                  <Field
                    label="Long Term"
                    name={"recurringDetailsTabIsLongTerm"}
                    type="checkbox"
                    as={Checkbox}
                    styles={{
                      root: {
                        paddingTop: "10px"
                      }
                    }}
                    disabled={true}
                  />
                </SimpleShimmerForForms>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

GLCreateRecurringJeDetails.displayName = "GLCreateRecurringJeDetails";

export { GLCreateRecurringJeDetails };
